import delay from 'delay';
import {constants,TARGET_TEST} from "./const";
import {  rpc, sc, u } from "@cityofzion/neon-js";

let neoline;
let neolineN3;

const url =TARGET_TEST ? "https://n3seed2.ngd.network:20332": "https://n3seed2.ngd.network:10332";

const rpcClient = new rpc.RPCClient(url);

const BASE58CHARS = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const initWalletApi = async (setAddress, setNetwork,) =>  {
    const initCommonDapi = new Promise((resolve, reject) => {
        window.addEventListener('NEOLine.NEO.EVENT.READY', () => {
            neoline = new window.NEOLine.Init();
            if (neoline) {
                resolve(neoline);
            } else {
                reject('common dAPI method failed to load.');
            }
        });
    });

    const initN3Dapi = new Promise((resolve, reject) => {
        window.addEventListener('NEOLine.N3.EVENT.READY', () => {
            neolineN3 = new window.NEOLineN3.Init();
            if (neolineN3) {
                resolve(neolineN3);
            } else {
                reject('N3 dAPI method failed to load.');
            }
        });
    });

    initCommonDapi.then(() => {
        neoline.addEventListener(neoline.EVENT.DISCONNECTED, () => {
            disconnect(setAddress);
        });
        neoline.addEventListener(neoline.EVENT.ACCOUNT_CHANGED, result => {
            setAddress(result.address);
        });
        neoline.addEventListener(neoline.EVENT.NETWORK_CHANGED, result => {
            setNetwork(result.defaultNetwork);
        });
        getNetwork(setNetwork);
        return initN3Dapi;
    }).then(() => {
        console.log('The N3 dAPI method is loaded.');
    }).catch((err) => {
        console.log(err);
    })
};

// 获取账户（唤起钱包）
function getAccount(updateAddress) {
    neoline.getAccount().then((account) => {
        updateAddress(account.address);
    }).catch((error) => convertWalletError(error));
}

// 获取资产
function getBalance(address, setBalance) {
    neolineN3.getBalance().then((result) => {
        const balance= {};
        console.log(result)
        result[address].forEach((v) => {
            balance[v.contract] = v.amount;
        });
        setBalance(balance);
        console.log(balance)
    }).catch((error) => convertWalletError(error));
}

function disconnect(updateAddress) {
    sessionStorage.removeItem('connect');
    updateAddress('');
}

function getNetwork(updateNetwork) {
    return neoline.getNetworks().then((result) => {
        updateNetwork(result.defaultNetwork);
        return result.defaultNetwork;
    }).catch((error) => {
        convertWalletError(error);
        return '';
    });
}

const GAS = "0xd2a4cff31913016155e38e474a2c06d08be276cf"

const generalProxy = TARGET_TEST?"0x11e0db0eef21399f415cb7d5b5ff9edf34e465a8":"0xe8964add6295cc0f1d4273d45ffbb10e231deccd"
const neoProxy =TARGET_TEST?"0x41a2d4872ed920aca01f1efc61fde44eaa95297d":"0xd29f20a634b4d8f31b14824eeba69ff0acdafe6d"


const TokenPath ={
    [constants['BNEO']]:[constants['BNEO'],GAS],
    [constants['FUSDT']]:[constants['FUSDT'],constants['FLM'],GAS],
    [constants['FWETH']]:[constants['FWETH'],constants['FLM'],GAS],
    [constants['FWBTC']]:[constants['FWBTC'],constants['FLM'],GAS],
    [constants['ONT']]:[constants['ONT'],constants['FLM'],GAS],
    [constants['FLM']]:[constants['FLM'],GAS],
}

const swapEx = (sender, originToken, amountIn, amountOutMin,showModal) =>{
    return neolineN3.invoke({
        scriptHash:originToken === constants['NEO']?neoProxy: generalProxy,
        operation: originToken === constants['NEO']?'swapNeo':'swapEx',
        args: [
            {
                type: 'Address',
                value: sender,
            },
            ...(originToken === constants['NEO'] ? [] : [
                {
                    type: 'Array',
                    value: TokenPath[originToken].map(hash => ({ type: 'Hash160', value: hash })),
                }
            ]),
            {
                type: "Integer",
                value: amountIn,
            },
            {
                type: "Integer",
                value: amountOutMin,
            }
        ],
        fee: '0',
        broadcastOverride: false,
        signers: [
            {
                account: originToken === constants['NEO']?neoProxy: generalProxy,
                scopes: 128
            },
            {
                account: addressToHash(sender),
                scopes: 128
            }
        ]
    }).then((result) => {
        showModal()
        return result.txid;
    }).catch((error) => {
        console.log(error);
        convertWalletError(error);
        return '-1';
    }).then(async (result) => {
        console.log(result);
        if (result === '-1') {
            return {
                status: 'error'
            };
        } else {
            const status = await getApplicationLog(result);
            return {
                status,
                txid: result
            };
        }
    });
}


const SWAP_ROUTER = TARGET_TEST? "0x13a83e059c2eedd5157b766d3357bc826810905e":"0xf970f4ccecd765b63732b821775dc38c25d74f23"
const getGasOut = async (amountIn,currentAsset)=>{
    const builder =  new sc.ScriptBuilder();
    const routesParam = TokenPath[currentAsset].map(r => {
        return sc.ContractParam.hash160(r);
      });
    builder.emitContractCall(
        {
            scriptHash:SWAP_ROUTER,
            operation: 'getAmountsOut',
            args: [
                sc.ContractParam.integer(amountIn),
                sc.ContractParam.array(...routesParam),
            ],
        });
        const response = await rpcClient.invokeScript(u.HexString.fromHex(builder.str).toBase64())
        return response;
}

// 地址转hash
const addressToHash = (address) => {
    return `0x${[...[...address].map(c => BASE58CHARS.indexOf(c)).reduce((acc, i) => acc.map(j => { const x = j * 58 + i; i = x >> 8; return x; }), new Uint8Array(25))].map(c => c.toString(16).padStart(2, '0')).join('').slice(8, 48)}`;
}

// 轮训获取日志
async function getApplicationLog(txid) {
    let status = '';
    let retryCount = 0;
    while (true) {
        await neolineN3.getApplicationLog({
            txid,
        }).then((result) => {
            if (result.executions[0].vmstate) {
                status = result.executions[0].vmstate === 'HALT' ? 'success' : 'error';
            } else {
                status = 'pending';
            }
        }).catch((error) => {
            convertWalletError(error);
            status = 'catchErr';
        });
        if (status === 'catchErr' || status === 'pending') {
            await delay(10000 * 1.5 ** retryCount);
            retryCount += 1;
            continue;
        }
        break;
    }
    return status;
}



// 钱包错误归总
function convertWalletError(error) {
    switch (error.type) {
        case 'NO_PROVIDER':
            console.log('No provider available.');
            break;
        case 'CONNECTION_DENIED':
            console.log('The user rejected the request to connect with your dApp');
            break;
        case 'CONNECTION_REFUSED':
            console.log('The user rejected the request to connect with your dApp');
            break;
        case 'RPC_ERROR':
            console.log('There was an error when broadcasting this transaction to the network.');
            break;
        case 'MALFORMED_INPUT':
            console.log('The receiver address provided is not valid.');
            break;
        case 'CANCELED':
            console.log('The user has canceled this transaction.');
            break;
        case 'INSUFFICIENT_FUNDS':
            console.log('The user has insufficient funds to execute this transaction.');
            break;
        case 'CHAIN_NOT_MATCH':
            console.log('The currently opened chain does not match the type of the call chain, please switch the chain.');
            break;
        default:
            console.error(error);
            break;
    }
}


export {
    initWalletApi,
    getAccount,
    getBalance,
    disconnect,
    getNetwork,
    swapEx,
    getGasOut,
}
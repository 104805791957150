const TARGET_TEST = process.env.REACT_APP_TARGET === 'testnet';

const constants = TARGET_TEST? {
    NEO: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    BNEO: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    FLM:'0x1415ab3b409a95555b77bc4ab6a7d9d7be0eddbd',
    '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5': 'NEO',
    '0x48c40d4666f93408be1bef038b6722404d9a4c2a':'BNEO',
    '0x1415ab3b409a95555b77bc4ab6a7d9d7be0eddbd':'FLM',
}
:  {
    NEO: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    BNEO: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    FUSDT: '0xcd48b160c1bbc9d74997b803b9a7ad50a4bef020', 
    FWETH: '0xc14b601252aa5dfa6166cf35fe5ccd2e35f3fdf5', 
    FWBTC: '0xd6abe115ecb75e1fa0b42f5e85934ce8c1ae2893', 
    FLM:'0xf0151f528127558851b39c2cd8aa47da7418ab28',
    ONT:'0x8122bc2212ec971690a044b37a6f52a9349b702b',
    '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5': 'NEO',
    '0x48c40d4666f93408be1bef038b6722404d9a4c2a':'BNEO',
    '0xcd48b160c1bbc9d74997b803b9a7ad50a4bef020':'FUSDT', 
    '0xc14b601252aa5dfa6166cf35fe5ccd2e35f3fdf5':'FWETH', 
    '0xd6abe115ecb75e1fa0b42f5e85934ce8c1ae2893':'FWBTC',
    '0xf0151f528127558851b39c2cd8aa47da7418ab28':'FLM',
    '0x8122bc2212ec971690a044b37a6f52a9349b702b':'ONT'
}

const token_decimals = {
    [constants['NEO']]: 0,
    [constants['BNEO']]: 8,
    [constants['FUSDT']]: 6, 
    [constants['FWETH']] : 18, 
    [constants['FWBTC']]: 8, 
    [constants['FLM']]:8,
    [constants['ONT']]:9
}

export {
    constants,
    token_decimals,
    TARGET_TEST
} 
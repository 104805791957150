import React, { useState, useEffect }  from "react"
import { initWalletApi, getAccount, disconnect, getBalance, swapEx,getGasOut} from './walletApi';
import {TwitterOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Modal ,Spin} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import arrow_logo from './asset/arrow.svg';
import logo_2 from './asset/logo_banner.svg';
import BNEO_logo from './asset/BNEO.svg';
import NEO_logo from './asset/NEO.svg';
import BTC_logo from './asset/BTC.svg';
import ETH_logo from './asset/ETH.svg';
import FLM_logo from './asset/FLM.svg';
import ONT_logo from './asset/ONT.svg';
import USDT_logo from './asset/USDT.svg';
import unlink from './asset/unlink.svg'
import gas_black from './asset/GAS_BLACK.svg'
import confirm from './asset/Confirm.svg'
import './GleederContainer.css';
import BigNumber from "bignumber.js";
import {constants,token_decimals,TARGET_TEST}from "./const";

const logo_map ={
    NEO: NEO_logo,
    BNEO: BNEO_logo,
    FUSDT: USDT_logo, 
    FWETH: ETH_logo, 
    FWBTC: BTC_logo, 
    FLM:FLM_logo,
    ONT:ONT_logo
}
function GleederContainer()  {
    const neo_hash = '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5'
    const [address, setAddress] = useState(''); // 钱包地址
	const [network, setNetwork] = useState(''); // 当前网络
    const [balance, setBalance] = useState({'0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5':0}); // 账户资产
    const [currentAsset,setCurrentAsset] =  useState('0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5');
    const [currentAmount,setCurrentAmount] =  useState('0')
    const [amountin,setAmountIn] =  useState("");
    const [amountOut,setAmountOut] =  useState('0');
    const [enableDisconnect, setEnableDisconnect] = useState(false); // 当前网络
    const [pending,setPending] =  useState(false);
    const [txid, setTxid] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isError, setIsError] = useState(true);


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        initWalletApi(setAddress, setNetwork);
    },[]);

    useEffect(() => {
		if (!!address) {
			getBalance(address, setBalance);
		}
	}, [address, network]);

    useEffect(() => {
        if(neo_hash in balance && balance[neo_hash]!==0){
            setCurrentAmount(balance[neo_hash])
        }else{
            setCurrentAsset(Object.keys(balance)[0])
            setCurrentAmount(balance[Object.keys(balance)[0]])
        }
        console.log(constants)
	}, [balance]);

    const connetWallet = (walletName) => {
        if(address){
            setEnableDisconnect(!enableDisconnect);
        }
        getAccount(setAddress);
    }

    const disconnectWallet = () => {
        disconnect(setAddress);
        setBalance({'0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5':0})
        setEnableDisconnect(false);
    }

    const handleChange = async (e) =>{
        setAmountIn(e.target.value);
        let decimal_amountin = ""
        let queryAsset = currentAsset
        if(currentAsset==constants['NEO']){
            queryAsset = constants['BNEO']
        }
        if ( e.target.value > 0 ) {
            let x = new BigNumber(e.target.value);
            decimal_amountin =  x.shiftedBy(token_decimals[queryAsset]);  
        }else{
            setAmountOut(0)
            setIsError(true)
        }
        console.log(decimal_amountin.toString())
        console.log(queryAsset)
        const resp = await getGasOut(decimal_amountin.toString(), queryAsset);
        console.log(resp);
        if (resp.state && resp.state  === 'HALT') {
            let output_index = 2;
            if(queryAsset == constants['BNEO'] ||queryAsset == constants['FLM'] ){
                output_index = 1
            }
            let y = new BigNumber(resp.stack[0].value[output_index].value);
            const decimal_y =  y.shiftedBy(-8).decimalPlaces(8,1).minus(0.5) ; 
            setAmountOut (decimal_y.toString());
            if(decimal_y.lte(0)){
                setAmountOut(0)
                setIsError(true)
            }else{
                setIsError(false)
            }
        }
      }

    const pickToken = (item) =>{
        setCurrentAsset(item.key);
        setCurrentAmount(balance[item.key]);
        setAmountIn(0)
        setAmountOut(0)
        setIsError(true)
    }
    
    const EXPLORE_BASE_URL = TARGET_TEST ? "https://testnet.explorer.onegate.space/transactionInfo/":"https://explorer.onegate.space/transactionInfo/"
    const clicktoSwapEx = async()=>{
        setIsError(false)
        setPending(true);
        // let queryAsset = currentAsset
        // if(currentAsset==constants['NEO']){
        //     queryAsset = constants['BNEO']
        // }
        let decimal_amountin = ""
        if ( amountin > 0 ) {
            let x = new BigNumber(amountin);
            decimal_amountin =  x.shiftedBy(token_decimals[currentAsset]);  
        }
        let y = new BigNumber(amountOut);
        const decimal_y =  y.shiftedBy(8); 
        if(decimal_y.gt(0)){
            const res = await swapEx(address,currentAsset,decimal_amountin.toString(),decimal_y.toString(),showModal);
            if(res['status'] === 'success'){
                setPending(false);
                setTxid(EXPLORE_BASE_URL+res['txid'])
            }
        }else{
            setIsError(true)
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
    function start_and_end(str) {
        if (str.length > 20) {
          return str.substr(0, 4) + '...' + str.substr(str.length-4, str.length);
        }
        return str;
      }

    
    const listItems = Object.keys(balance).map((d) => (d in constants) && 
    <Menu.Item key={d}>
             <div className="item-wrapper">
                <div>
                    <img className="token-icon" alt="icon" src={logo_map[constants[d]]}/>
                    <span className="token-text">{constants[d]}</span>
                </div>
                <div>
                    <span className="token-amount">{balance[d]}</span>
                </div>
            </div>
    </Menu.Item>);
    const menu = (
        <Menu onClick={(item)=>{pickToken(item)}}>
            {listItems}
        </Menu>
      );

    return (
      <div>
       <Modal visible={isModalVisible} footer={false} closable={false}>
           <div className="modal-wrapper">
            <p style={{"marginTop":"20px"}}>
            {pending && <Spin/>}
            {!pending &&  <img src={confirm} alt="logo" />}
            </p>
            <div className="modal-wiggy">
            {pending && <div className="modal-title">
                    Swapping
                </div>}
            {!pending &&   <div className="modal-title">
                    Swapped
                </div>}
                {pending &&
                <div className="modal-content">
                    Transaction needs 15~30 seconds to be confirmed.
                </div>
                }
                {!pending &&
                <div className="modal-content">
                    Transaction confirmed.
                    <br/>
                    <a href={txid} target="_blank" rel="noreferrer">View your Tx in explorer</a>
                </div>
                }
            </div>
            <button onClick={handleOk} className="close-button">CLOSE</button>
           </div>
      </Modal>
        <div className="nav-bar">
            <img src={logo_2} className="nav-bar-logo" alt="logo" />
            <button className="nav-bar-button" onClick={() => connetWallet('Neoline')}>{ !!address ? start_and_end(address):'Connect NeoLine'}</button>
            {enableDisconnect && <button className="disconnect-button" onClick={() => disconnectWallet()}><img src={unlink} className="arrow-logo" alt="logo" />Disconnect</button>}
        </div>
        <div className="container">
            {/* <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header> */}
            <div className="function-container">
                <div className="box-container">
                    <div className="left-box box-wrapper">
                        <div className="tip-wrapper">
                            <div>
                                I have
                            </div>
                            <div>
                                Available {currentAmount} {constants[currentAsset]}
                            </div>
                        </div>
                        <div className="input-wrapper">
                            <div className="styled-select-drop">
                                <Dropdown overlay={menu} overlayClassName={"token-list"} placement="bottomLeft" trigger={['click']}>                          
                                    <div className="picker" style={{"cursor":"pointer"}}>
                                        <div className="token-list">
                                            <img className="token-icon" alt="logo" src={logo_map[constants[currentAsset]]}/>
                                                <span className="token-text">{constants[currentAsset]}</span>
                                                <DownOutlined  style={{"color":"black","marginLeft":"5px"}}/>
                                        </div>
                                    </div>    
                                </Dropdown>
                            </div>
                            <div style={{"marginRight":"10px", "marginTop":"10px"}}>
                                <input className="token_input" type="number" placeholder="0" onChange={ handleChange }  value={amountin} ></input>
                            </div>
                        </div>
                    </div>
                    <div className="arrow-container">
                        <img src={arrow_logo} className="arrow-logo" alt="logo" />
                    </div>
                    <div className={`right-box box-wrapper ${isError && address !='' ? "disab" : ""}`}>
                        <div className="tip-wrapper">
                            <div>
                                I will get approximately
                            </div>  
                        </div>
                        <div className="input-wrapper">
                            <div className="token-list">
                                <img className="token-icon" alt="logo" src={gas_black}/>
                                <span className="token-text">GAS</span>
                            </div>
                            <div style={{"marginRight":"10px", "marginTop":"10px"}}>
                                <input className="token_input" type="text" readOnly value={amountOut} ></input>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="button-container">
                    <button onClick={clicktoSwapEx} className={`swap-button ${isError ? "disab" : ""}`}>SWAP</button>
                </div>
            </div>
            <div className="text-container">
                <div className="text-container-title">What is Gleeder?</div>
                <div className="text-container-body">Neo’s two token model (NEO & GAS) requires users to pay GAS token as a transaction fee. 
                    <br/>
                    Gleeder allows users to get GAS token even if your address doesn’t have it. 
                    <br/>
                    If you are a project owner, you can contact Steven<a href="https://twitter.com/rendongliu"><TwitterOutlined style={{"color":"white"}}/></a> to insert this function into your project.
                </div>
            </div>
        </div>
      </div>
    )
  }
export default GleederContainer